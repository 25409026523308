import { v4 as uuidv4 } from 'uuid';

export default {
    async addCalories({ getters, commit }, { data, chosenDate }) {
        commit('addCaloriesToDay', { data, chosenDate })

        const dataFromStore = getters.getCalories[chosenDate]

        dataFromStore.ref.set({
            [data.type]: dataFromStore[data.type]
        } , { merge: true })
    },
    async deleteCalories({ getters, commit }, { id, type, chosenDate }) {
        commit('deleteCaloriesToDay', { id, type, chosenDate })

        const dataFromStore = getters.getCalories[chosenDate]

        dataFromStore.ref.set({
            [type]: dataFromStore[type]
        } , { merge: true })
    },
    async fetchDay({ getters, rootGetters, commit }, date) {
        const { getCalories } = getters

        if (getCalories[date]) return getCalories[date]
        else {
            const { caloriesRef } = rootGetters["authentication/getCurrentUser"]

            if (caloriesRef) {
                const ref = caloriesRef.collection('dates').doc(date)
                const day = (await ref.get()).data()

                if (day?.ate) day.ate = day.ate.map(element => ({ ...element, id: uuidv4() }))
                if (day?.burned) day.burned = day.burned.map(element => ({ ...element, id: uuidv4() }))

                const data = { key: date, value: day || {}, ref  }

                commit('addDay', data)

                return data
            }
        }
    },
}
