<template>
  <v-dialog v-model="controller">
    <v-card>

      <v-card-title>
        Rozkład procentowy posiłków:
      </v-card-title>

      <v-col
          v-for="([, value], index) in Object.entries(getCurrentUser?.data?.caloriesTarget || {})"
          :key="index"
      >
        <v-row v-for="(element, index) in headersOrder" :key="index">
          <v-col>
            {{ translations[element] }} ({{ getPercent(element) }}%):
          </v-col>
          <v-col>
            {{ kcalText(calculateCalories(value, getPercent(element))) }}
          </v-col>
        </v-row>
      </v-col>

      <v-btn color="primary" class="text-none" @click="controller=false">Rozumiem</v-btn>

    </v-card>
  </v-dialog>
</template>

<script>

import { mapGetters } from "vuex";

export default {
  name: "Popup",
  data: () => ({
    controller: false,
    headersOrder: [ 'breakfast', 'lunch', 'dinner', 'teatime', 'supper' ],
    translations: {
      'breakfast': 'Śniadanie',
      'lunch': 'II śniadanie',
      'dinner': 'Obiad',
      'teatime': 'Podwieczorek',
      'supper': 'Kolacja',
    }
  }),
  mounted () {
    this.controller = !!this.getCurrentUser?.data?.percentMeals && !!this.getCurrentUser?.data?.caloriesTarget
  },
  methods: {
    calculateCalories (value, percent) {
      return Math.floor(~~value * (percent / 100))
    },
    kcalText (value) {
      return `${value} kcal`
    },
    getPercent (key) {
      return this.getCurrentUser?.data.percentMeals?.[key]
    }
  },
  computed: {
    ...mapGetters('authentication', [ 'getCurrentUser' ]),

  }
}
</script>

<style scoped>

</style>
