<template>
  <v-app id="app">
    <div id="loader" v-if="loading">
      <v-progress-circular indeterminate/>
    </div>
    <router-view v-else/>
    <popup v-if="showPercentMeals"/>
  </v-app>
</template>

<script setup>
import { ref, onBeforeMount, computed, watch, onMounted } from "vue";
import { useCore } from '@/composables/core'
import Popup from "./components/Popup.vue";

const loading = ref(true)
const showPercentMeals = ref(false)

const { store } = useCore()

const isAuthenticated = computed(() => store.getters['authentication/getCurrentUser'])

const fetch = async () => {
  const response = await store.dispatch('authentication/checkCurrentUser')
  if (response) {
    await store.dispatch('authentication/fetchUserDataFromDb')
  }
  loading.value = false
}

onMounted(() => {
  let timeout = setTimeout(() => {
    if (store.getters['authentication/getCurrentUser']?.data?.showPercentMeals) {
      showPercentMeals.value = true
    }

    clearTimeout(timeout)
  }, 1000)
})

onBeforeMount(fetch)

watch(isAuthenticated, (curr, prev) => {
  if (curr && !prev) {
    fetch()
  }
})

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#loader {
  z-index: 2;
  background-color: white;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
