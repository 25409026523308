<template>
  <v-col>
    <v-btn @click="logIn">Zaloguj</v-btn>
  </v-col>
</template>

<script setup>
import { useCore } from '@/composables/core'
import { onMounted } from "vue"

const { router, store } = useCore()

onMounted(() => {
  if (store.getters['authentication/getCurrentUser']) {
    router.push('dashboard')
  }
})

const logIn = async () => {
  try {
    await store.dispatch('authentication/authWithGoogle')
    router.push('dashboard')
  } catch (err) {
    console.error(err)
  }
}
</script>


