<template>
  <v-col>
    <v-btn @click="router.push('login')">Do strony logowania</v-btn>
  </v-col>
</template>

<script setup>
import { useCore } from '@/composables/core'
import { onMounted } from "vue"

const { router, store } = useCore()

onMounted(() => {
  if (store.getters['authentication/getCurrentUser']) {
    router.push('login')
  }
})
</script>