import Vue from 'vue'
import Vuex from 'vuex'
import authentication from './authentication'
import access from './access'
import calories from './calories'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    authentication,
    access,
    calories
  }
})
