export default {
    setCurrentUser(state, payload) {
        state.currentUser = payload
    },
    updateCurrentUser(state, { key, value }) {
        state.currentUser[key] = value
    },
    setDataKey(state, { value, key }) {
        state.currentUser.data[key] = value
    }
}
