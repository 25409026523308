<template>
  <v-sheet>
    <v-slide-group
        :show-arrows="!$vuetify.breakpoint.smAndDown"
        v-model="dateModel"
        center-active
    >
      <template #prev>
        <v-btn
            class="mr-2"
            height="100%"
            dark
            small
            color="grey darken-1"
        >
          <v-icon dark>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </template>
      <template #next>
        <v-btn
            class="ml-2"
            height="100%"
            dark
            small
            color="grey darken-1"
        >
          <v-icon dark>
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </template>
      <v-slide-item
          v-for="({ date, dayName }, index) in dates"
          :key="index"
          v-slot="{ active, toggle }"
      >
        <v-btn
            :color="active ? 'primary' : 'grey lighten-1'"
            class="mx-1 px-3 text-none"
            :input-value="active"
            depressed
            height="100px"
            width="100px"
            @click="toggle"
        >
          <v-col>
            <v-row>
              {{ dayName }}
            </v-row>
            <v-row>
              {{ date | cropDate}}
            </v-row>
          </v-col>
        </v-btn>
      </v-slide-item>
    </v-slide-group>
  </v-sheet>
</template>

<script>
import moment from 'moment'

export default {
  name: "DateOptions",
  data: () => ({
    dateModel: undefined,
    dates: []
  }),
  filters: {
    cropDate (date) {
      const [day, month, year] = date.split('.')
      return `${day}.${month}.${year.substring(2,4)}`
    },
  },
  watch: {
    dateModel(value) {
      this.$emit('date-picked', this.dates[value].date)
    }
  },
  mounted() {
    let counter = 0
    let date = moment().subtract(15, 'days')

    while (counter < 30) {
      this.dates.push({
        date: date.format("DD.MM.YYYY"),
        dayName: ['Nd.', 'Pon.', 'Wt.', 'Śr.', 'Czw.', 'Pt.', 'Sob.'][date.day()]
      })
      date = moment(date).add(1, 'd')
      counter += 1
    }

    this.dateModel = 15
  }
}
</script>