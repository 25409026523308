<template>
  <div v-if="items.length">
    <v-select outlined v-model="mainCategory" dense :items="items" item-text="name" return-object/>

    <v-select v-model="subCategories[index]" :key="index" outlined dense v-for="(categories, index) in nextCategories" :items="categories" item-text="name"
              return-object/>

    <div v-if="apiData.length">
      <v-row class="pa-0 ma-0">
        <v-col class="pa-0 pr-2 ma-0">
          <v-select dense outlined v-model="kcalOption" :items="apiData" item-text="amount" return-object/>
        </v-col>
        <v-col class="pa-0 pl-2 ma-0">
          <v-text-field dense outlined v-model="portionAmount" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "FitatuSearcher",
  data: () => ({
    items: [],
    mainCategory: undefined,
    nextCategories: [],
    subCategories: [],
    apiData: [],
    kcalOption: undefined,
    portionAmount: 1
  }),
  props: {
    fitatu: {
      type: Object,
      default: () => ({})
    },
    dialog: {
      type: Boolean
    }
  },
  mounted() {
    this.items = JSON.parse(JSON.stringify(this.fitatu?.categories)) || []
  },
  methods: {
    async renderCategory(value) {
      if (value?.type === 'category') {
        const response = await (await fetch('https://us-central1-calories-8a213.cloudfunctions.net/fetch/f_searcher', {
          method: 'POST',
          body: JSON.stringify({
            url: value.href
          })
        })).json()

        if (response[0].href) this.nextCategories.push(response)
        else this.apiData = response
      }
    },
    sendInfo() {
      this.$emit('ext-calories-change', {
        value: parseInt(this.portionAmount) * parseInt(this.kcalOption.energy.replace( /^\D+/g, '')),
        name: this.subCategories[this.subCategories.length -1].name.trim()
      })
    }
  },
  watch: {
    dialog() {
      this.nextCategories = []
      this.apiData = []
      this.subCategories = []
      this.portionAmount = 1
      this.mainCategory = undefined
    },
    kcalOption() {
      this.sendInfo()
    },
    portionAmount() {
      this.sendInfo()
    },
    subCategories(value) { // TODO: Handle render better - rechange
      this.renderCategory(value[value.length - 1])
    },
    mainCategory(value) {
      this.nextCategories = []
      this.apiData = []
      this.portionAmount = 1
      this.renderCategory(value)
    },
    fitatu(value) {
      this.items = JSON.parse(JSON.stringify(value?.categories)) || []
    },
  }
}
</script>

<style scoped>

</style>
