<template>
  <v-container id="container">
    <v-row>
      <v-col>
        <date-options @date-picked="onDatePicked"/>
      </v-col>
    </v-row>

    <v-row class="mb-16">
      <v-col v-bind="cols">
        <add-calories
            :chosen-date="chosenDate"
            :ext-calories="extCalories"
            :type="types.ate"
            name="Zjedzone:"
            @popup-change="onPopupChange"
        >
          <fitatu-searcher
              v-if="isFitatuAvailable"
              :dialog="dialog"
              :fitatu="fitatu"
              @ext-calories-change="onExtCaloriesChange"
          />
        </add-calories>
        <calories-table
            @calories-count="onCaloriesCount"
            :type="types.ate"
            :chosen-date="chosenDate"
            class="mt-4"
        />
      </v-col>

      <v-col v-bind="cols">
        <add-calories
            :chosen-date="chosenDate"
            :type="types.burned"
            name="Spalone:"
        />
        <calories-table
            @calories-count="onCaloriesCount"
            :type="types.burned"
            :chosen-date="chosenDate"
            class="mt-4 mb-16"
        />
      </v-col>
    </v-row>

    <v-footer height="80">
      <v-row class="pl-4 font-weight-bold" justify="space-between">
        <v-col class="pa-0 ma-0 text-left calc-diff" align-self="center">
          Bilans kaloryczny: {{ caloriesBalance }} kcal
        </v-col>
        <v-col class="text-right" align-self="center">
          <template v-if="ateBalance">
            <v-icon
                dark
                left
                :color="getColor"
                small
            >
              {{ getIcon }}
            </v-icon>
            <span :class="`${getColor}--text calc-diff`">{{ Math.abs(ateBalance) }} kcal</span>
          </template>
        </v-col>
      </v-row>
    </v-footer>
  </v-container>
</template>

<script>
import DateOptions from "@/components/DateOptions";
import AddCalories from "@/components/AddCalories";
import CaloriesTable from "@/components/CaloriesTable";
import FitatuSearcher from "@/components/FitatuSearcher";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Dashboard",
  components: { DateOptions, AddCalories, CaloriesTable, FitatuSearcher },
  data: () => ({
    isFitatuAvailable: false,
    fitatu: {
      categories: []
    },
    extCalories: {},
    cols: {
      md: 6,
      cols: 12,
    },
    balance: {
      ate: 0,
      burned: 0
    },
    chosenDate: undefined,
    types: {
      burned: 'burned',
      ate: 'ate',
    },
    dialog: undefined
  }),
  async mounted () {
    try {
      if (this.getCurrentUser.data?.fitatuFetch) {
        this.fitatu.categories = await (await fetch('https://us-central1-calories-8a213.cloudfunctions.net/fetch/f_searcher', {
          method: 'POST',
          body: JSON.stringify({
            url: 'https://www.fitatu.com/catalog/pl',
            isInitial: true
          })
        })).json()

        this.isFitatuAvailable = true
      }
    } catch (e) {
      this.isFitatuAvailable = false
    }
  },
  computed: {
    ...mapGetters('authentication', [ 'getCurrentUser' ]),
    getColor () {
      return this.ateBalance > 0 ? 'red' : 'green'
    },
    getIcon () {
      return `mdi-arrow-${this.ateBalance > 0 ? 'up' : 'down'}`
    },
    caloriesBalance () {
      return this.balance.ate - this.balance.burned
    },
    ateBalance () {
      return this.balance.ate - this.getCurrentUser?.data?.caloriesTarget?.major || 0
    }
  },
  methods: {
    ...mapActions('calories', [ 'fetchDay' ]),
    onPopupChange (value) {
      this.dialog = value
      this.extCalories = {}
    },
    onExtCaloriesChange (value) {
      this.extCalories = value
    },
    onCaloriesCount ({ amount, type }) {
      if (type === this.types.ate) this.balance.ate = amount
      if (type === this.types.burned) this.balance.burned = amount
    },
    onDatePicked (date) {
      this.chosenDate = date
      this.fetchDay(date)
    }
  }
}
</script>

<style scoped>

#container {
  width: 100%;
  max-width: unset;
}

.calc-diff {
  font-size: 14px;
}

.v-footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
