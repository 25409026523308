import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from "firebase/app";
import "firebase/firestore";
import vuetify from './plugins/vuetify'

import { createCore } from './composables/core'

createCore({ router, store })

firebase.initializeApp({
    apiKey: "AIzaSyAyBHhpSrQG3ZlypPgIxN_5qPrNOWWvnaY",
    authDomain: "calories-8a213.firebaseapp.com",
    projectId: "calories-8a213",
    storageBucket: "calories-8a213.appspot.com",
    messagingSenderId: "732103691705",
    appId: "1:732103691705:web:c6aa495784583acce5ba68",
    measurementId: "G-QKFGZH71SS"
})

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')



