<template>
  <v-row justify="space-between" class="ma-0 pa-0">
    <v-col align-self="center" class="pl-0">{{ name }}</v-col>

    <v-dialog v-model="dialog" width="500px">
      <v-card class="pa-8">

        <v-row class="pa-0 ma-0">
          <v-col class="font-weight-bold pl-0" align-self="center">
            {{ titles[type] }}:
          </v-col>
          <v-col align-self="center">
            <v-row justify="end">
              <v-btn
                  dark
                  color="indigo"
                  @click="addHandler"
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="ma-0">
          <v-col class="pa-0">
            <slot/>

            <div v-if="isAteType">
              <v-text-field
                  dense
                  outlined
                  v-model="data.searchPhrase"
                  :placeholder="titles.searchPhrase"
                  @blur="fetchMealFromBase"
              />

              <v-select
                  v-if="data.fetchedMeals.length"
                  :items="data.fetchedMeals"
                  v-model="data.clickedFetchedMeal"
                  item-text="name"
                  return-object
              />

              <v-row v-if="Object.keys(data.clickedFetchedMeal?.nutritionalValues || {}).length" class="pa-0 ma-0"
                     justify="end">
                <v-col class="pa-0 pr-4 ma-0" style="display: flex; align-items: flex-end">
                  <v-text-field dense outlined v-model="data.amountFetchedMeal" :placeholder="titles.amountName"/>
                </v-col>
                <v-col class="pa-0 ma-0">
                  <v-select :items="Object.keys(data.clickedFetchedMeal.nutritionalValues)" item-text="amount"
                            @change="onMealChosen"/>
                </v-col>
              </v-row>

              <v-divider class="my-2"/>
            </div>

            <v-text-field dense outlined v-model="data.name" :placeholder="titles.mealName"/>
            <v-text-field dense outlined v-model="data.amount" :placeholder="titles.mealKcal"/>

            <v-row class="pa-0 ma-0" v-if="isAteType"> <!-- TODO: v for -->
              <v-col class="pa-0">
                <v-text-field dense outlined v-model="data.protein" :placeholder="titles.protein"/>
              </v-col>
              <v-col class="pa-0 mx-2">
                <v-text-field dense outlined v-model="data.carbohydrate" :placeholder="titles.carbohydrate"/>
              </v-col>
              <v-col class="pa-0">
                <v-text-field dense outlined v-model="data.fat" :placeholder="titles.fat"/>
              </v-col>
            </v-row>
          </v-col>

        </v-row>
      </v-card>
    </v-dialog>

    <v-col align-self="center">
      <v-row justify="end">
        <v-btn
            color="success"
            @click="dialog = true"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-row>
    </v-col>

  </v-row>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: "AddCalories",
  props: {
    chosenDate: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: undefined
    },
    extCalories: {
      type: Object,
      default: () => ({})
    },
    name: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    dialog: false,
    titles: {
      ate: 'Zjedzone',
      burned: 'Spalone',
      protein: 'Białko',
      carbohydrate: 'Węglowodany',
      fat: 'Tłuszcze',
      mealName: 'Nazwa posiłku',
      mealKcal: 'Kalorie',
      searchPhrase: 'Wyszukaj w bazie',
      amountName: 'Ilość',
    },
    cols: {
      cols: 12,
      sm: 9,
      xs: 12,
    },
    data: {
      amount: '',
      name: '',
      searchPhrase: '',
      fetchedMeals: [],
      clickedFetchedMeal: {},
      amountFetchedMeal: 1,
    }
  }),
  watch: {
    extCalories ({ value, name }) {
      this.data.amount = value
      this.data.name = name
    },
    dialog (value) {
      this.$emit('popup-change', value)
    }
  },
  computed: {
    isAteType () {
      return this.type === 'ate'
    }
  },
  methods: {
    ...mapActions('calories', ['addCalories']),
    addHandler () {
      this.data.amount = parseInt(this.data.amount)

      // TODO: MAKE IT BETTER
      if (this.isAteType) {
        this.data.protein = parseFloat(this.data.protein)
        this.data.carbohydrate = parseFloat(this.data.carbohydrate)
        this.data.fat = parseFloat(this.data.fat)
      }

      this.addCalories({ data: { ...this.data, type: this.type }, chosenDate: this.chosenDate })

      this.data = {
        amount: '',
        name: '',
        searchPhrase: '',
        fetchedMeals: [],
        clickedFetchedMeal: {},
        amountFetchedMeal: 1,
      }
    },
    fetchMealFromBase () {
      let url
      if (window.location.hostname === 'localhost') {
        url = 'http://127.0.0.1:5001/calories-8a213/us-central1'
      } else {
        url = 'https://us-central1-calories-8a213.cloudfunctions.net'
      }

      fetch(`${ url }/api/meal/search/etK1fMo8mXhTmxrdSso8kl5eXs73/tencug-3fosgy-qesveD/${ this.data.searchPhrase }`)
          .then(async data => {
            this.data.fetchedMeals = await data.json()
          })
    },

    onMealChosen (e) {
      const chosen = this.data.clickedFetchedMeal?.nutritionalValues[e]
      
      this.data.name = `${this.data.amountFetchedMeal} x ${ chosen.amount } ${ this.data.clickedFetchedMeal.name }`
      this.data.amount = ~~this.data.amountFetchedMeal * chosen.kcal

      this.data.protein = ~~this.data.amountFetchedMeal * chosen.protein
      this.data.carbohydrate = ~~this.data.amountFetchedMeal * chosen.carbohydrates
      this.data.fat = ~~this.data.amountFetchedMeal * chosen.fat
    }
  }
}
</script>

<style lang="scss">

.v-text-field__details {
  display: none !important;
}
</style>
