import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import Dashboard from '@/views/Dashboard.vue'
import Settings from '@/views/Settings.vue'
import AuthenticatedDashboard from "@/templates/AuthenticatedDashboard"
import Calculator from "@/views/Calculator"

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/dashboard',
        component: AuthenticatedDashboard,
        children: [
            {
                path: '/',
                name: 'dashboard',
                component: Dashboard,
            },
        ]
    },
    {
        path: '/settings',
        component: AuthenticatedDashboard,
        children: [
            {
                path: '/',
                name: 'settings',
                component: Settings,
            },
        ]
    },
    {
        path: '/calculator',
        component: AuthenticatedDashboard,
        children: [
            {
                path: '/',
                component: Calculator
            }
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
