<template>
  <div class="authenticated-dashboard">
    <v-app-bar
        dark
        height="80"
    >
      <v-app-bar-title>
        <v-avatar @click="drawerController = !drawerController">
          <v-img :src="store.getters['authentication/getCurrentUser']?.photoURL"/>
        </v-avatar>
      </v-app-bar-title>
      <v-spacer/>
      <v-btn text @click="logout">Wyloguj</v-btn>
    </v-app-bar>

    <v-navigation-drawer
        v-model="drawerController"
        absolute
        temporary
        class="pa-2"
    >
      <v-btn @click="router.push(btn.to)" v-for="(btn, index) in buttons" :key="index" v-bind="btn.bind">
        {{ btn.name }}
      </v-btn>

    </v-navigation-drawer>
    <router-view/>
  </div>
</template>

<script setup>
import { useCore } from '@/composables/core'
import { onMounted, ref } from "vue";

const { router, store } = useCore()
const drawerController = ref(false)

const buttons = [
  {
    name: 'Tablica',
    to: 'dashboard',
    bind: {
      width: '100%'
    }
  },
  {
    name: 'Ustawienia',
    to: 'settings',
    bind: {
      width: '100%',
      class: 'mt-2',
    }
  },
  {
    name: 'Kalkulator',
    to: 'calculator',
    bind: {
      width: '100%',
      class: 'mt-2',
    }
  }
]

onMounted(() => {
  if (!store.getters['authentication/getCurrentUser']) {
    router.push('/')
  }
})

const logout = async () => {
  await store.dispatch('authentication/logoutUser')
  router.push('/')
}
</script>

