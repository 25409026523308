export default {
    setCalories(state, payload) {
        state.calories = payload
    },
    setTodayDate(state, payload) {
        state.todayDate = payload
    },
    consumedSet(state, payload) {
        state.calories.consumed.values = payload
    },
    activitiesSet(state, payload) {
        state.calories.activities.values = payload
    },
    weightSet(state, payload) {
        state.weight = payload
    },
    setWeightInDay(state, payload) {
        const before = state.weight
        before[payload.index] = payload.value
        state.weight = [...state.weight]
    },
    addDay(state, {key, value, ref }) {
        state.calories[key] = { ...value, ref }

        state.calories = { ...state.calories }
    },
    deleteCaloriesToDay(state, { id, type, chosenDate }) {
        const index = state.calories[chosenDate][type].findIndex(element => element.id === id)
        if (index !== -1) state.calories[chosenDate][type].splice(index, 1)
    },
    addCaloriesToDay(state, {chosenDate, data}) {
        if (!state.calories[chosenDate]?.[data.type]) state.calories[chosenDate][data.type] = []

        state.calories[chosenDate][data.type].push(data)

        state.calories = {...state.calories}
    }
}
