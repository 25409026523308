import firebase from 'firebase'

export default {
    async fetchUserDataFromDb ({ rootGetters, commit }) {
        const currentUser = rootGetters['authentication/getCurrentUser']

        const userRef = firebase.firestore().doc(`users/${currentUser.uid}`)
        commit('updateCurrentUser', { key: 'ref', value: userRef })

        const data = (await userRef.get()).data()
        commit('updateCurrentUser', { key: 'data', value: data })

        const caloriesRef = firebase.firestore().doc(`calories/${data.caloriesId}`)
        commit('updateCurrentUser', { key: 'caloriesRef', value: caloriesRef })
    },
    async authWithGoogle ({ commit, getters }) {
        if (!getters.getCurrentUser) {
            const provider = new firebase.auth.GoogleAuthProvider()
            const response = await firebase.auth().signInWithPopup(provider)
            commit('setCurrentUser', response.user)
        }
    },
    checkCurrentUser ({ commit }) {
        return new Promise((resolve) => { // TODO: HANDLE REJECT
            firebase.auth().onAuthStateChanged(user => {
                if (user) {
                    commit('setCurrentUser', user)
                    resolve(user)
                }
                resolve(false)
            })
        })
    },
    async logoutUser ({ commit }) {
        await firebase.auth().signOut()
        commit('setCurrentUser', null)
    }
}
