<template>
  <v-container>
    <v-row v-for="(option, index) in switchOptions" :key="index">
      <v-col align-self="center">{{ option.name }}</v-col>
      <v-col>
        <v-row justify="end">
          <v-switch
              v-model="option.value"
              @click="option.handler(option.value)"
              color="success"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-row v-for="(option, index) in inputOptions" :key="index">
      <v-col align-self="center">{{ option.name }}</v-col>
      <v-col>
        <v-row justify="end">
          <v-text-field
              v-model="option.value"
              @blur="option.handler(option.value)"
              outlined
          />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>

import { useCore } from '@/composables/core'
import { ref } from "vue";

const { store } = useCore()

const switchOptions = [
  {
    value: ref(store.state.authentication.currentUser.data?.fitatuFetch || false),
    name: 'Baza fitatu:',
    handler: (value) => {
      store.state.authentication.currentUser.ref.set({
        fitatuFetch: value
      } , { merge: true })

      store.commit('authentication/setDataKey', { value, key: 'fitatuFetch' })
    }
  },
  {
    value: ref(store.state.authentication.currentUser.data?.showPercentMeals || false),
    name: 'Procentowy rozkład posiłków:',
    handler: (value) => {
      store.state.authentication.currentUser.ref.set({
        showPercentMeals: value
      } , { merge: true })

      store.commit('authentication/setDataKey', { value, key: 'showPercentMeals' })
    }
  }
]

const inputOptions = [
  {
    value: ref(store.state.authentication.currentUser.data?.caloriesTarget?.major || 0),
    name: 'Cel kaloryczny:',
    handler: (value) => {
      const obj = {
        caloriesTarget: {
          major: Number.parseInt(value)
        }
      }
      store.state.authentication.currentUser.ref.set(obj , { merge: true })

      store.commit('authentication/setDataKey', { value: obj, key: 'caloriesTarget' })
    }
  },
  {
    value: ref(store.state.authentication.currentUser.data?.height || 0),
    name: 'Wzrost:',
    handler: (value) => {
      const obj = {
        height: Number.parseInt(value)
      }
      store.state.authentication.currentUser.ref.set(obj , { merge: true })

      store.commit('authentication/setDataKey', { value: obj, key: 'height' })
    }
  },
  {
    value: ref(store.state.authentication.currentUser.data?.age || 0),
    name: 'Wiek:',
    handler: (value) => {
      const obj = {
        age: Number.parseInt(value)
      }
      store.state.authentication.currentUser.ref.set(obj , { merge: true })

      store.commit('authentication/setDataKey', { value: obj, key: 'age' })
    }
  }
]
</script>
