<script setup>
import { useCore } from '@/composables/core'
import { onMounted } from "vue";
const { store } = useCore()

onMounted(() => window.init({
  initHeight: store.state.authentication.currentUser.data?.height,
  initAge: store.state.authentication.currentUser.data?.age,
  size: 400
}))
</script>

<template>
  <div id="calculator" class="mt-4" />
</template>

<style>
#calculator {
  display: flex;
  justify-content: center;
}
</style>
