<template>
  <div v-if="getCurrent.length">
    <v-row class="table-header mx-0 px-0">
      <v-col
          v-for="(element, index) in headers"
          :cols="element.cols"
          :key="index"
      >
        {{ element.name }}
      </v-col>
    </v-row>

    <v-row
        v-for="data in getCurrent"
        :key="data.id"
        class="table-row text-sm mx-0 px-0"
    >
      <v-col :cols="headers[0].cols">
        {{ data.name }}
      </v-col>

      <template v-if="isAteType">
        <v-col cols="1" v-for="(item, index) in renderTds" :key="`ate-type-${data.id}-${index}`">
          {{ data[item] || '' }}
        </v-col>
      </template>

      <v-col :cols="isAteType ? 2 : 5">
        {{ data.amount }}
      </v-col>
      <v-col cols="1" align-self="center">
        <v-row justify="center" class="ma-0">
          <v-btn
              x-small
              class="pa-0"
              text
              @click="deleteRow(data.id)"
          >
            <v-icon dark>
              mdi-delete
            </v-icon>
          </v-btn>
        </v-row>

      </v-col>

    </v-row>

    <v-row v-if="isAteType" class="table-summary">
      <v-col :cols="headers[0].cols"/>
      <v-col cols="1" v-for="(item, index) in renderTds" :key="`item-${index}`">
        {{ count(item) || 0 }}{{ g }}
      </v-col>
      <v-col>{{ countTotal }} {{ kcal }}</v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: "CaloriesTable",
  props: {
    chosenDate: {
      type: String,
      default: undefined
    },
    type: {
      type: String
    }
  },
  data: () => ({
    burnedHeaders: [
      {
        name: 'Nazwa',
        cols: 6
      },
      {
        name: 'Kcal',
        cols: 6
      },
    ],
    ateHeaders: [
      {
        name: 'Nazwa',
        cols: 6
      },
      ...[ 'B', 'W', 'T' ].map(e => ({
        name: e,
        cols: 1
      })),
      {
        name: 'Kcal',
        cols: 2
      } ],
    renderTds: [ 'protein', 'carbohydrate', 'fat' ],
    kcal: 'kcal',
    g: 'g' // TODO: Better
  }),
  watch: {
    countTotal (value) {
      this.$emit('calories-count', { amount: value, type: this.type })
    }
  },
  methods: {
    ...mapActions('calories', [ 'deleteCalories' ]),
    deleteRow (id) {
      if (confirm()) {
        this.deleteCalories({ id, type: this.type, chosenDate: this.chosenDate })
      }
    },
    count (key) {
      return Math.round(this.getCurrent.reduce((prev, next) => prev + (next[key] || 0), 0))
    }
  },
  computed: {
    ...mapGetters('calories', [ 'getCalories' ]),
    isAteType () { // TODO: It is duplicated
      return this.type === 'ate'
    },
    headers () {
      return this.isAteType ? this.ateHeaders : this.burnedHeaders
    },
    countTotal () {
      return this.getCurrent.reduce((prev, next) => prev + next.amount, 0)
    },
    getCurrent () {
      return this.getCalories[this.chosenDate]?.[this.type] || []
    }
  }
}
</script>

<style scoped lang="scss">

.table-header {
  background: #BDBDBD;
  font-size: 14px;
}

.table-summary {
  font-size: 12px;
}

.table-row {
  border-top: 2px solid white;
  background: #EEEEEE;
  font-size: 12px;

  .v-btn {
    min-width: auto !important;
  }
}
</style>
